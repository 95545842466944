<template>
    <ul v-if="carts.length">
        <li
            v-for="cart in carts"
            :key="cart.id"
        >
            <RouterLink :to="`/carts/${cart.id}`">{{ cart.name }}</RouterLink>
        </li>
    </ul>

    <p v-else>
        Vi hittade inga kundvagnar lagrade på din enhet.
    </p>
</template>

<script>
export default {
    name: 'CartsList',
    props: {
        carts: {
            type: Array,
            required: true,
        },
    },
};
</script>
