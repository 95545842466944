const STORAGE_KEY = 'carts_id';

export default {
    getAll() {
        const data = localStorage.getItem(STORAGE_KEY);
        if (!data) {
            return [];
        }

        return JSON.parse(data);
    },

    save(id) {
        const data = this.getAll();

        data.push(id);

        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    },
};
