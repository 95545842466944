import router from '@/router'

router.addRoute({
	path: '/carts/get',
	name: 'GetCart',
	component: () => import('./pages/GetCart.vue'),
});
router.addRoute({
	path: '/carts/create',
	name: 'CreateCart',
	component: () => import('./pages/CreateCart.vue'),
});
router.addRoute({
	path: '/carts/:id',
	name: 'Cart',
	component: () => import('./pages/Cart.vue'),
});
