<template>
    <main>
        <h1>Page not found</h1>
    </main>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>
