import httpRepo from '@/core/httpRepo'

export default {
    ...httpRepo('carts'),

    /**
     * @param {string} id
     * @param {string} itemId
     * @param {Object} data
     * @returns {Promise<string|Object>}
     */
    putItem(id, itemId, data) {
        return this.put(`${id}/${itemId}`, data);
    },

    /**
     * @param {string} id
     * @param {string} itemId
     * @returns {Promise<string|Object>}
     */
    deleteItem(id, itemId) {
        return this.delete(`${id}/${itemId}`);
    },
};
