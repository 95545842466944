<template>
    <div>
        <FlashMessage />

        <header id="header">
            <h1>Ullacart</h1>

            <nav>
                <ul>
                    <li>
                        <RouterLink to="/">Start</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/carts/create">Ny kundvagn</RouterLink>
                    </li>
                    <li>
                        <RouterLink to="/carts/get">Öppna kundvagn</RouterLink>
                    </li>
                </ul>
            </nav>
        </header>

        <RouterView />
    </div>
</template>

<script>
export default {
    name: 'App',
    components: {
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0 auto;
    max-width: 1080px;
    background: #0057b8;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 100%;
    color: #fedb00;
}

@media screen and (max-width: 1200px) {
    body {
        margin-left: 10px;
        margin-right: 10px;
    }
}

input, textarea, select, option, button {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1em;
}

#header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

#header h1 {
    font-size: 2em;
}

#header nav ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

#header nav ul li a {
    display: block;
    padding: 0.5em;
}

a {
    color: #fedb00;
    text-decoration: none;
}

a:hover, a:active, a:focus {
    text-decoration: underline;
}

h1 {
    font-size: 1.6em;
}

h2 {
    font-size: 1.4em;
}

h3 {
    font-size: 1.2em;
}

.cell-currency {
    text-align: right;
}
</style>
