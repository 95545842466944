import HttpErrorResponse from './HttpErrorResponse'

/**
 * @param {RequestInfo} url
 * @param {RequestInit} requestOptions
 * @returns {Promise<string|Object>}
 */
async function baseFetch(url, requestOptions) {
    if (!url.startsWith('http')) {
        url = process.env.VUE_APP_API_URL + url;
    }

    const response = await fetch(url, requestOptions);

    return await handleResponse(response);
}

async function getFetch(url, headers = {}) {
    const requestOptions = {
        method: 'GET',
        headers,
    };

    return baseFetch(url, requestOptions);
}

async function postFetch(url, body, headers = {}) {
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    };

    return baseFetch(url, requestOptions);
}

async function putFetch(url, body, headers = {}) {
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
    };

    return baseFetch(url, requestOptions);
}

async function deleteFetch(url, headers = {}) {
    const requestOptions = {
        method: 'DELETE',
        headers,
    };

    return baseFetch(url, requestOptions);
}

/**
 * @param {Response} response
 * @returns {Promise<string|Object>}
 */
async function handleResponse(response) {
    const text = await response.text();
    const data = text && JSON.parse(text);

    if (!response.ok) {
        return Promise.reject(new HttpErrorResponse(
            response.headers,
            response.status,
            response.statusText,
            response.url,
            data,
        ));
    }

    return data;
}

/**
 * Parses the API exception errors
 *
 * @param {HttpErrorResponse} response
 * @return {Array<String>}
 */
export function getErrors(response) {
    const data = response.data;
    let errors = [];

    if (data && data.details && data.details.length) {
        errors = Object.values(data.details);
    } else {
        errors.push((data && data.error) || response.statusText);
    }

    return errors;
}

export const http = {
    get: getFetch,
    post: postFetch,
    put: putFetch,
    delete: deleteFetch,
    getErrors,
};
