import { http } from '@/core/http'

export default (repo) => {
    return {
        /**
         * @param {Record<string, string>} query
         * @param {Record<string, string>} headers
         * @returns {Promise<string|Object>}
         */
        getAll(query = undefined, headers = {}) {
            let url = `/api/${repo}`;

            if (typeof query === 'object') {
                const searchParams = new URLSearchParams(query);

                url += `?${searchParams.toString()}`;
            }

            return http.get(url, headers);
        },

        /**
         * @param {id|string} id
         * @param {Record<string, string>} headers
         * @returns {Promise<string|Object>}
         */
        get(id, headers = {}) {
            return http.get(`/api/${repo}/${id}`, headers);
        },

        /**
         * @param {Record<string, string>} headers
         * @param {Object} data
         * @returns {Promise<string|Object>}
         */
        post(data, headers = {}) {
            return http.post(`/api/${repo}`, data, headers);
        },

        /**
         * @param {id|string} id
         * @param {Object} data
         * @param {Record<string, string>} headers
         * @returns {Promise<string|Object>}
         */
        put(id, data, headers = {}) {
            return http.put(`/api/${repo}/${id}`, data, headers);
        },

        /**
         * @param {id|string} id
         * @param {Record<string, string>} headers
         * @returns {Promise<string|Object>}
         */
        delete(id, headers = {}) {
            let url = `/api/${repo}`;

            if (typeof id !== 'undefined') {
                url += `/${id}`;
            }

            return http.delete(url, headers);
        },
    };
};
