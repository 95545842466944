import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import FlashMessage from '@smartweb/vue-flash-message'

// Modules
import './modules/cart'

createApp(App)
    .use(router)
    .use(FlashMessage)
    .mount('#app');
