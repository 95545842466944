export default class HttpErrorResponse {
    /**
     * @type {Headers}
     */
    headers;
    /**
     * @type {number}
     */
    status;
    /**
     * @type {string}
     */
    statusText;
    /**
     * @type {string}
     */
    url;
    /**
     * @type {Object}
     */
    data;

    constructor(headers, status, statusText, url, data) {
        this.headers = headers;
        this.status = status;
        this.statusText = statusText;
        this.url = url;
        this.data = data;
    }
}
