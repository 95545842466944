<template>
	<main>
		<h1>Dina senaste kundvagnar</h1>

		<CartsList :carts="carts" />
	</main>
</template>

<script>
import { getErrors } from '@/core/http'
import cartsLocalStorage from '../cartsLocalStorage'
import cartsHttp from '../cartsHttp'
import CartsList from '../components/CartsList'

export default {
	name: 'Home',
	components: {
		CartsList,
	},
	data() {
		return {
			carts: [],
		};
	},
	created() {
		cartsLocalStorage
			.getAll()
			.forEach(async cartId => {
				try {
					this.carts.push(await this.fetchCart(cartId));
				} catch (err) {
					const errors = getErrors(err);

					errors.forEach(text => {
						this.$flashMessage.show({
							type: 'error',
							text,
						});
					});
				}
			})
		;
	},
	methods: {
		async fetchCart(id) {
			return await cartsHttp.get(id);
		},
	},
};
</script>

<style scoped>
</style>
