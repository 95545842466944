import {
    createRouter,
    createWebHistory,
} from 'vue-router'
import Home from './modules/home/pages/Home'
import NotFound from '@/NotFound'

export default createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: NotFound,
        }
    ],
});
